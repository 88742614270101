import { createSlice } from "@reduxjs/toolkit"

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    isMenuOpen: false,
    isMenuMobileOpen: false,
    activeLinkText: "",
    activePatternMenu: "",
    activePage: "",
    activeLanguage: "en",
  },
  reducers: {
    openMenu: state => {
      state.isMenuOpen = !state.isMenuOpen
    },
    openMenuMobile: state => {
      state.isMenuMobileOpen = !state.isMenuMobileOpen
    },
    closeMenuMobile: state => {
      state.isMenuMobileOpen = false
    },
    setActiveLinkText: (state, action) => {
      state.activeLinkText = action.payload
    },
    setActivePatternMenu: (state, action) => {
      state.activePatternMenu = action.payload
    },

    setActivePage: (state, action) => {
      state.activePage = action.payload
    },

    setActiveLanguage: (state, action) => {
      state.activeLanguage = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  openMenu,
  openMenuMobile,
  setActiveLinkText,
  setActivePatternMenu,
  setActivePage,
  setActiveLanguage,
} = menuSlice.actions

export default menuSlice.reducer
